<header class="main-header">
	<nav class="navbar">
		<div class="navbar-left h-100">
			<a class="fs-20 d-inline-flex" routerLink="/myknow">
				<img
					*ngIf="theme?.showLogoInNavBar && tenant.logo; else noLogo"
					class="client-logo"
					[src]="tenant.logo | blobAccessToken"
					[attr.alt]="tenant.name"
					[style.width.px]="logoWidth">
				<ng-template #noLogo>
					<app-icon class="top-icon" type="FontAwesome" [icon]="['fas', 'grid']"></app-icon>
				</ng-template>
			</a>
		</div>
	</nav>
</header>