import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TenantResolverService } from 'src/app/shared/tenant-resolver.service';
import { ITenantThemeViewModel, Tenant } from 'src/app/tenants/tenants.models';
import { LayoutUtilsService } from '../layout-utils.service';

@Component({
	selector: 'basic-header',
	templateUrl: './basic-header.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BasicHeaderComponent implements OnInit {

	tenant: Tenant;
	theme: ITenantThemeViewModel;

	constructor (
		private readonly tenantResolverService: TenantResolverService,
		private readonly layoutUtilsService: LayoutUtilsService
	) { }

	get logoWidth (): number {
		return this.layoutUtilsService.calcLogoWidth(this.theme.logoWidth);
	}

	ngOnInit (): void {
		this.tenant = this.tenantResolverService.getTenant();
		this.theme = this.tenant.theme;
	}
}
